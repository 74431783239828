import { PublicUserDocument } from '@models/user';
import { Elements } from '@stripe/react-stripe-js';
import Button from '@ui/Button';
import { logInitCheckout } from '@util/analytics';
import { getCartItemsSubtotal } from '@util/firestore/cart';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getCartItemFromProductDocument, isMobile } from '@util/index';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useStripeContext } from 'context/StripeContext';
import dayjs from 'dayjs';
import { ProductDocument, Variation } from 'models/product';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
const MakeAnOfferModal = dynamic(() => import('../modals/MakeAnOfferModal'));

export const BuyAddOfferButtons = ({
  product,
  seller,
  selectedVariations,
  onAddToCartError,
  onAddToCart,
  hideOffer,
}: {
  product: ProductDocument;
  seller?: PublicUserDocument | null;
  selectedVariations: Variation[] | null;
  onAddToCartError: () => void;
  onAddToCart?: () => void;
  hideOffer?: boolean;
}) => {
  const { userDoc } = useAuth();
  const { increaseCartQty, canAddProductToCart, setCartOpen } =
    useShoppingCart();
  const [openModal, setOpenModal] = React.useState(false);
  const { setChatOpen } = useChat();
  const { stripePromise } = useStripeContext();
  const { getAttribution } = useAttributionContext();
  const router = useRouter();
  const pathname = usePathname();

  function hasValidVariations() {
    return (
      !product.has_variations ||
      (product.category2 === 'Gear Combos' &&
        selectedVariations?.length === 2) ||
      product.category2 !== 'Gear Combos'
    );
  }

  const handleAddToCart = () => {
    if (userDoc) {
      if (canAddProductToCart(product.id)) {
        if (hasValidVariations()) {
          const attribution = getAttribution(product.id);
          const cartItem = getCartItemFromProductDocument(
            product,
            selectedVariations,
            attribution
          );
          increaseCartQty(cartItem);
          // algoliaInsights('convertedObjectIDs', {
          //   index: process.env.NEXT_PUBLIC_IS_STAGING ? 'products' : 'products',
          //   eventName: 'product added to cart',
          //   objectIDs: [product.id],
          // });
        } else {
          onAddToCartError();
        }
      } else {
        setCartOpen(true);
      }
      onAddToCart?.();
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  const handleBuyNow = () => {
    if (userDoc) {
      if (hasValidVariations()) {
        if (canAddProductToCart(product.id)) {
          const cartItem = getCartItemFromProductDocument(
            product,
            selectedVariations
          );
          increaseCartQty(cartItem, true); // true to skip opening the cart drawer
          // userToken is set globally already, so we don't need to specify it here
          // applies to other calls to algoliaInsights too
          // algoliaInsights('convertedObjectIDs', {
          //   index: process.env.NEXT_PUBLIC_IS_STAGING ? 'products' : 'products',
          //   eventName: 'product checkout opened',
          //   objectIDs: [product.id],
          // });
        }
        logInitCheckout(
          [product],
          getCartItemsSubtotal([
            getCartItemFromProductDocument(product, selectedVariations),
          ]),
          userDoc?.uid ?? ''
        );
        router.push('/checkout');
      } else {
        onAddToCartError();
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  const { status } = useOnlineStatus(seller?.uid);
  const today = dayjs();
  const lastChangeDate = dayjs(status?.last_changed);
  const lastSeenInWeeks = today.diff(lastChangeDate, 'week');
  return (
    <>
      <div className="flex flex-row gap-[1.6rem]">
        {(!userDoc || status || process.env.NODE_ENV === 'development') &&
          lastSeenInWeeks < 2 && (
            <Button
              text="Add to Cart"
              width="fluid"
              type="gray"
              disabled={product.out_of_stock}
              onClick={handleAddToCart}
            />
          )}

        {!hideOffer && (
          <Button
            type="gray"
            width="fluid"
            text="Make An Offer"
            disabled={product.out_of_stock}
            onClick={() => {
              if (userDoc) {
                if (hasValidVariations()) {
                  setOpenModal(true);
                } else {
                  onAddToCartError();
                }
              } else {
                router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
              }
            }}
          />
        )}
      </div>
      {(!userDoc || status || process.env.NODE_ENV === 'development') &&
        lastSeenInWeeks < 2 && (
          <Button
            type="secondary"
            text="Buy Now"
            width="fluid"
            disabled={product.out_of_stock}
            onClick={handleBuyNow}
          />
        )}

      {openModal && (
        <Elements stripe={stripePromise}>
          <MakeAnOfferModal
            isOpen={openModal}
            dismiss={(openDrawer) => {
              setOpenModal(false);
              if (openDrawer) {
                if (isMobile()) {
                  router.push('/dashboard/offers');
                } else {
                  setChatOpen(true);
                }
              }
            }}
            product={product}
            selectedVariations={selectedVariations}
          />
        </Elements>
      )}
    </>
  );
};

export default BuyAddOfferButtons;
