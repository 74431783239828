import WishlistAction from '@c/toasts/WishlistAction';
import {
  addProductToFavorites,
  removeProductFromFavorites,
} from '@util/firestore/products';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { HeartIcon } from '..';
import { SvgIconProps } from '../types';
import { usePathname, useRouter } from 'next/navigation';
import { trackUserInteraction } from '@util/firestore/recommendations';

interface HeartIconButtonProps {
  itemId: string;
  initialCount?: number;
  hideCount?: boolean;
}
const HeartIconButton = ({
  itemId,
  initialCount = 0,
  hideCount = false,
}: HeartIconButtonProps) => {
  const likedProps: SvgIconProps = {
    pathProps: {
      stroke: 'none',
      fill: '#FF4242',
      className: 'hover:none',
    },
  };
  const defaultProps: SvgIconProps = {
    pathProps: {
      stroke: 'currentColor',
      fill: 'none',
    },
  };
  const [isLiked, setLiked] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(initialCount);

  const { userDoc } = useAuth();
  const router = useRouter();
  const pathname = usePathname() ?? '';
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  useEffect(() => {
    if (userDoc) {
      const liked = userDoc.favorites?.includes(itemId);
      setLiked(!!liked);
    }
  }, [itemId, userDoc]);

  const props = isLiked ? likedProps : defaultProps;

  const onButtonClick = () => {
    setLiked(!isLiked);
    if (pathname.includes('create-listing')) return; // preview mode;
    if (userDoc) {
      if (isLiked) {
        removeProductFromFavorites(userDoc.uid, itemId);
        setFavoriteCount(favoriteCount - 1);
        trackUserInteraction({
          uid: userDoc.uid,
          pid: itemId,
          interaction: 'unfavorite',
        });
      } else {
        addProductToFavorites(userDoc.uid, itemId);
        setFavoriteCount(favoriteCount + 1);
        trackUserInteraction({
          uid: userDoc.uid,
          pid: itemId,
          interaction: 'favorite',
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['authUser'],
      });
      queryClient.invalidateQueries({
        queryKey: ['favorites', userDoc?.uid],
      });
      showToast(<WishlistAction removed={isLiked} />);
    } else router.push(`/login?redirect=${pathname}`);
  };

  return (
    <button
      className={`${
        !isLiked ? 'hover:bg-brand-light-gray' : ''
      } min-h-[3.2rem] min-w-[3.2rem] select-none rounded-brand bg-brand-white sm:p-[0.8rem]`}
      onClick={() => onButtonClick()}
    >
      {initialCount !== undefined ? (
        <span className="flex items-center gap-[0.8rem] px-4">
          {!hideCount && (
            <span className="font-semibold">{Math.max(favoriteCount, 0)}</span>
          )}
          <HeartIcon
            {...props}
            svgProps={{
              height: '18',
              width: '18',
            }}
          />
        </span>
      ) : (
        <HeartIcon
          {...props}
          svgProps={{
            height: '18',
            width: '18',
          }}
        />
      )}
    </button>
  );
};

export default HeartIconButton;
