import { ReviewDocument } from '@models/review';
import { db } from '@util/firebase';
import {
  addDoc,
  arrayUnion,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { ProductDocument } from 'models/product';
import { addReviewedSeller } from '../orders';
import { getPublicUserDoc, getUserById } from '../users';

const reviewsRef = collection(
  db,
  'reviews'
) as CollectionReference<ReviewDocument>;

export const getReviewByOrderId = async (id: string) => {
  const q = query(reviewsRef, where('order_id', '==', id), limit(1));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return null;
  }
  const data = snapshot.docs?.[0]?.data() ?? null;
  return data as ReviewDocument | null;
};

export const getReviewByOrderIdAndSellerId = async ({
  id,
  seller_id,
}: {
  id: string;
  seller_id: string;
}) => {
  const q = query(
    reviewsRef,
    where('order_id', '==', id),
    where('uid', '==', seller_id),
    limit(1)
  );
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return null;
  }
  const data = snapshot.docs?.[0]?.data() ?? null;
  return data as ReviewDocument | null;
};

export const submitReview = async (review: Partial<ReviewDocument>) => {
  if (!review.uid) return null;
  const reviewDoc = await addDoc(reviewsRef, review);
  if (reviewDoc.id) {
    if (review.order_id) {
      await addReviewedSeller(review.order_id, review.uid);
    }
    // update user rating
    const userDoc = await getPublicUserDoc({ uid: review.uid, noCache: true });
    if (userDoc) {
      const rating =
        (userDoc.rating * userDoc.reviews + (review.rating ?? 0)) /
        (userDoc.reviews + 1);
      await updateDoc(doc(db, 'users', review.uid), {
        rating,
        reviews: increment(1),
      });
    }
  }
  return reviewDoc.id;
};

export const getReviewsByUid = async (uid: string) => {
  const q = query(
    reviewsRef,
    where('uid', '==', uid),
    orderBy('created', 'desc'),
    limit(40)
  );
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
};

export const cleanProductReview = (product: ProductDocument) => {
  return {
    id: product.id,
    title: product.title,
    thumbnail: product.thumbnail,
    description: product.description,
    category: product.category,
  };
};

export const removeReview = async (id: string) => {
  const review = await getDoc(doc(db, 'reviews', id));
  if (!review.exists()) return;
  const data = review.data();
  const sellerDoc = await getUserById(data.uid);
  try {
    await deleteDoc(doc(db, 'reviews', id));
    if (sellerDoc) {
      const rating =
        (sellerDoc.rating * sellerDoc.reviews - (data.rating ?? 0)) /
        (sellerDoc.reviews - 1);
      await updateDoc(doc(db, 'users', data.uid), {
        rating,
        reviews: increment(-1),
      });
    }
  } catch (e) {
    alert((e as Error).message);
  }
};

export const submitReply = async ({
  reviewId,
  comment,
  username,
  uid,
  photo,
}: {
  reviewId: string;
  comment: string;
  username: string;
  uid: string;
  photo: string;
}) => {
  const reply = { comment, username, uid, photo, created: Date.now() };
  await updateDoc(doc(db, 'reviews', reviewId), {
    replies: arrayUnion(reply),
  });
};

export const removeReply = async ({
  reviewId,
  reply,
}: {
  reviewId: string;
  reply: any;
}) => {
  const d = await getDoc(doc(db, 'reviews', reviewId));
  if (!d.exists()) return;
  const replies = d.data().replies;
  if (!replies) return;
  const newReplies = replies.filter((r: any) => r.created !== reply.created);
  await updateDoc(doc(db, 'reviews', reviewId), { replies: newReplies });
};
export interface StaticReview {
  id: string;
  img: string;
  name: string;
  content: string;
  googleReviewLink?: string;
}
