import { ButtonToggleType } from '@util/types/buttontoggle';
import { useState } from 'react';

interface ButtonToggleProps {
  buttons: ButtonToggleType[];
}

function renderButtons(
  buttons: ButtonToggleType[],
  setSelectedButton: (key: string) => void,
  selectedButton?: string
) {
  const getRadiusClass = (index: number) => {
    if (index === 0) {
      return 'rounded-bl-xl rounded-tl-xl';
    }
    if (index === buttons.length - 1) {
      return 'rounded-br-xl rounded-tr-xl';
    }
    return '';
  };

  return buttons.map((button, index) => {
    const isSelected = button.key === selectedButton;
    return (
      <button
        key={button.key}
        type="button"
        className={`
          ${getRadiusClass(index)}
          ${
            isSelected
              ? 'bg-black text-white'
              : 'bg-[#F1F1F1] text-black hover:bg-black hover:text-white'
          }
          flex h-[3.5rem] min-w-[4rem] items-center justify-center px-3 transition-colors
          ${button.disabled ? 'pointer-events-none opacity-50' : ''}
        `}
        onClick={() => {
          setSelectedButton(button.key);
          button.onClick?.();
        }}
      >
        {typeof button.icon === 'function' ? (
          button.icon({ color: isSelected ? 'white' : 'currentColor' })
        ) : button.icon ? (
          <span className={isSelected ? 'text-white' : 'text-current'}>
            {button.icon}
          </span>
        ) : (
          <span className={isSelected ? 'text-white' : 'text-current'}>
            {button.label}
          </span>
        )}
      </button>
    );
  });
}

const ButtonToggle = ({ buttons }: ButtonToggleProps) => {
  const initiallySelectedButton = buttons.find((button) => button.selected);
  const [selectedButton, setSelectedButton] = useState(
    initiallySelectedButton?.key
  );
  return (
    <div className="flex items-center !text-white">
      {renderButtons(buttons, setSelectedButton, selectedButton)}
    </div>
  );
};

export default ButtonToggle;
